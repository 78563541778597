import type { ModelBaseDef } from "@/utils/defs";
import { expandThumbs } from "paparazzi/components/stores/thumbnail_conversion";
import type { Ref } from "vue";
import { uapi } from "./api";

import {
  colorOptions,
  metalOptions,
  type Mirror,
  type Product,
} from "@/stores/defs/shop_defs";
import { createLogger } from "@paparazzi/utils/debug";
import api from "@virgodev/bazaar/functions/api";
import copy from "@virgodev/bazaar/functions/copy";
import localforage from "localforage";
import sortBy from "sort-by";
import { safeDateParse } from "./utilities";

const log = createLogger("patcher");
const detailLog = createLogger("patcher-detail");

const timeoutDuration = import.meta.env.NODE_ENV === "test" ? 10 : 2000;

interface ExtraDef {
  stock?: number;
  timestamp?: Date;
}

export class Patcher<T extends ModelBaseDef> {
  url: string;
  array: Ref<T[]>;
  percent: Ref<number>;
  cache: LocalForage;
  pending: ModelBaseDef[] = [];
  keys: (string | number)[] = [];
  patching: number = 0;
  cleanup: number = 0;
  isPatching: boolean = false;
  sortFields?: string[];
  onCompleted?: Function;

  // Warning: setting the page size to 50 will cause NGINX
  // to time out servers because of the size of the get request
  // for products.
  pageSize = 25;
  promise: Promise<void> | null = null;
  _resolve: Function | null = null;
  _cacheResolve: Function | null = null;
  _cachePromise: Promise<void>;
  attr: "id" | "slug" = "id";
  mirror?: Mirror;

  constructor(
    url: string,
    array: Ref<T[]>,
    percent: Ref<number>,
    mirror?: Mirror,
    sortFields?: string[],
    onCompleted?: Function,
  ) {
    this.url = url;
    this.array = array;
    this.percent = percent;
    this.cache = localforage.createInstance({
      name: "paparazzi",
      storeName: `${url}_cache`,
    });
    this._cachePromise = new Promise((resolve) => {
      this._cacheResolve = resolve;
    });
    this.mirror = mirror;
    this.sortFields = sortFields;
    this.onCompleted = onCompleted;
    this.setup();
  }

  setup() {}

  getKey(model: ModelBaseDef): string {
    return (model[this.attr] || "n/a").toString();
  }

  async loadCache() {
    let expired = 0;
    const uncached: ModelBaseDef[] = [];
    await this.cache.iterate((value: any, key: string) => {
      if (value) {
        try {
          const cutoff = Date.now() - 1000 * 60 * 60 * 48;
          if (
            value.cached_time &&
            new Date(value.cached_time).getTime() > cutoff
          ) {
            uncached.push(value);
          } else {
            this.cache.removeItem(`${key}`);
            expired += 1;
          }
        } catch (ex) {
          console.warn("error in iteration", ex);
        }
      } else {
        console.warn("remove from cache", key);
        this.cache.removeItem(`${key}`);
      }
    });

    for (const value of expandThumbs(uncached)) {
      // const index = this.array.value.findIndex((i) => i.id === value.id);
      // if (index === -1) {
      this.array.value.push(this.migrateItem(value));
      // }
    }

    if (expired) {
      log("expired", this.url, expired, "objects");
    }
    log("loaded from cache", this.url, this.array.value.length);
    if (this._cacheResolve) {
      this._cacheResolve();
      this._cacheResolve = null;
    }

    // finish product sync
    if (uncached.length > 0) {
      await this.finishPatch();
    }
  }

  async patch(id: string | number, extra: ExtraDef = {}) {
    if (this._cacheResolve) {
      await this._cachePromise;
    }

    let retval = null;
    const alreadyPatching = this.pending.find((p) => p.id === id);
    const existing = this.array.value.find(
      (p: ModelBaseDef) => this.getKey(p) == id,
    );
    const skip = id === "_updated" || this.shouldSkip(extra, existing);
    if (!alreadyPatching && !skip) {
      // console.warn("adding to pending", this.url, id, skip);

      retval = new Promise((r) => {
        this.pending.push({ id, extra, resolve: r });
      });
    }

    if (!this.isPatching) {
      this.isPatching = true;
      this.promise = new Promise((resolve) => {
        this._resolve = resolve;
      });
    }

    // record keys we've seen
    this.keys.push(id);

    // if (retval && !this.isPatching) {
    this.finishPatch();
    // }
    return retval;
  }

  async remove(key: string | number) {
    const index = this.keys.findIndex((a) => a === key);
    if (index > -1) {
      this.keys.splice(index, 1);
    }

    const item = this.array.value.findIndex((a) => this.getKey(a) === key);
    if (item > -1) {
      const [a] = this.array.value.splice(item, 1);
      this.cache.removeItem(`${a.id}`);
    }
  }

  shouldSkip(extra: ExtraDef, existing?: ModelBaseDef): boolean {
    if (!existing) {
      return false;
    }
    if (extra.timestamp && existing.timestamp) {
      let date: Date | undefined = undefined;
      if (["number", "string"].includes(typeof existing.timestamp)) {
        date = new Date(existing.timestamp as unknown as string | number);
      } else {
        date = existing.timestamp;
      }
      return date >= extra.timestamp;
    }
    return true;
  }

  async finishPatch(timeout = timeoutDuration) {
    clearTimeout(this.patching);
    this.patching = window.setTimeout(
      async () => {
        const failed = [];

        // log("pending", this.url, this.pending.length);
        let slice = this.pending.splice(0, this.pageSize);
        let count = 0;
        const totalCount = this.pending.length;
        const times = [];
        const maxAttempts = 3;
        const speed = 1000;
        while (slice.length > 0) {
          let response = null;
          let attempts = maxAttempts;

          while (response === null && attempts > 0) {
            try {
              const startTime = Date.now();
              // log(
              //   `${this.url} patching`,
              //   slice.length,
              //   "items;",
              //   this.pending.length,
              //   "remaining in queue",
              // );

              response = await uapi({
                url: `${this.url}/patch/`,
                params: {
                  ids: slice.map((p) => p.id),
                  msgpack: true,
                  dt: Date.now(),
                },
                // POST will do a compare
                method: "GET",
              });

              times.push(Date.now() - startTime);
              if (
                response &&
                attempts === maxAttempts &&
                response.body.length < slice.length
              ) {
                log(
                  this.url,
                  "failed to get all requested items, trying again",
                  response.body.length,
                  "vs",
                  slice.length,
                );

                // DEBUG: if set, this will make the patch try again
                // otherwise, it will use the REST api to get each individually
                // await timer(5 * speed);
                // response = null;
              }
            } catch (ex) {
              log(`failed to fetch ${this.url}`);
              console.log("fail", ex);
              await new Promise((r) =>
                setTimeout(r, (5 - attempts) * speed + Math.random() * speed),
              );
            }
            attempts -= 1;
          }
          if (response === null) {
            console.warn(
              "We failed to fetch products at this time, please try again in a moment",
            );
            return;
          }

          const responseItems = [];
          if (response.ok) {
            for (const item of response.body) {
              let item_id = `${item[this.attr]}`;
              const instance = this.migrateItem(item);
              const object = slice.find((p) => p.id === item_id);
              if (object) {
                if (object.extra) {
                  for (const key in object.extra) {
                    instance[key as keyof T] = object.extra[key];
                  }
                }
                // if (object.old && (!instance.images || instance.images.length === 0)) {
                //   instance.images = object.old.images;
                // }
                if (object.resolve) {
                  object.resolve(instance);
                }
                object.resolved = true;
              }
              responseItems.push(instance);
              count += 1;
              this.percent.value = count / totalCount;
              // this.current.push(item[attr]);
            }
          }
          if (responseItems.length < slice.length) {
            log(
              `${this.url} failed to get items: ${responseItems.length} vs ${slice.length}`,
            );
            for (const item of slice) {
              if (!responseItems.find((i) => i[this.attr] === item.id)) {
                log(` - missing ${item.id} (${this.url})`);
                const response = await api({
                  url: `${this.url}/${item.id}/`,
                });
                if (response.ok) {
                  responseItems.push(response.body);
                }
              }
            }
          }
          for (const part of slice.filter((p) => !p.resolved)) {
            if (part.resolve) {
              part.resolve(null);
            }
          }
          for (let item of expandThumbs(responseItems)) {
            item.cached_time = new Date();
            this.cache.setItem(`${item.id}`, copy(item));
            const index = this.array.value.findIndex((i) => {
              return i[this.attr] === item[this.attr];
            });
            if (index === -1) {
              // detailLog(this.url, "added", item);
              this.array.value.push(item);
            } else {
              // detailLog(this.url, "updated", item);
              this.array.value[index] = item;
            }
          }

          slice = this.pending.splice(0, this.pageSize);
        }

        const total = this.array.value.length;
        if (times.length > 0) {
          const avg = times.reduce((a, b) => a + b, 0) / times.length;
          log(`${this.url} patch average time: ${avg}ms`);
          log(`${this.url} patch complete [${count}] (total: ${total})`);
        } else {
          log(`${this.url} patch unneeded [${total}]`);
        }

        this.isPatching = false;
        if (this._resolve) {
          this._resolve();
        }

        this.purgeFromKeySync();
        this.clearOld();

        if (this.sortFields) {
          this.array.value.sort(sortBy(...this.sortFields));
        }

        this.percent.value = 1;
        this.completed();
      },
      this.pending.length >= this.pageSize ? 0 : timeout,
    );
  }

  migrateItem(a: T): T {
    return a;
  }

  purgeFromKeySync() {
    // log("purging...", this.url);
    if (this.mirror) {
      const remove: (string | number)[] = [];
      if (this.mirror.after || this.mirror.limit) {
        for (const obj of this.array.value) {
          const key = this.getKey(obj);
          if (!this.mirror.data[key]) {
            remove.push(key);
          }
        }
      } else {
        for (const key in this.mirror.data) {
          if (!this.keys.includes(key)) {
            remove.push(key);
          }
        }
      }
      if (remove.length > 0) {
        for (const key of remove) {
          const index = this.array.value.findIndex(
            (p) => this.getKey(p) === `${key}`,
          );
          if (index !== -1) {
            const itemId = this.array.value[index].id;
            log("purging", this.url, key, index, this.array.value.length);
            delete this.mirror.data[key];
            this.mirror.queue.push({
              action: "delete",
              key: key,
            });
            this.cache.removeItem(`${itemId}`);
            this.array.value.splice(index, 1);
          }
        }
      }
    }
    // log("purging... complete", this.url);
  }

  clearOld() {
    clearTimeout(this.cleanup);
    this.cleanup = window.setTimeout(
      () => {
        let count = 0;
        let index = 0;
        for (const p of this.array.value) {
          if (!p) {
            this.array.value.splice(index, 1);
            count += 1;
          } else if (
            // this.current[url].indexOf(p[attr]) === -1 ||
            (p.stock || 0) < -1
          ) {
            const index = this.array.value.findIndex((i) => {
              return i.id === p.id;
            });
            if (index > -1) {
              this.array.value.splice(index, 1);
              this.cache.removeItem(`${p.id}`);
            }
            console.warn("remove oos from cache", this.url, p[this.attr]);

            count += 1;
          }
          index += 1;
        }
        if (count > 0) {
          log(`cleared ${count} stale ${this.url}`);
        }
      },
      1000 * 60 * 5,
    );
  }

  completed() {
    if (this.onCompleted) {
      this.onCompleted(this);
    }
  }
}

export class ProductPatcher<T extends Product> extends Patcher<T> {
  colors = [
    ...metalOptions.map((o) => o.label),
    ...colorOptions.map((o) => o.label),
    ...["Rose Gold", "Gunmetal"],
  ];

  setup() {
    this.attr = "slug";

    // TODO: a way to add a bad item into the list
    // const item = {
    //   id: 74397,
    //   remote_id: "P2SE-SVXX-214XX",
    //   name: "Hoedown Throwdown",
    //   description:
    //     '<P> A silver chain necklace features an array of Western-inspired charms, including cowboy boots, cacti, a cowboy hat, a silver horse, a sheriff\'s badge, and a studded horseshoe, adding a playful, country-themed vibe. The charms are suspended along a sleek silver chain for a dynamic and textured look. Features an adjustable clasp closure. </p>\n\n<P><i>Sold as one individual necklace. Includes one pair of matching earrings.  </i></p>\n\n<img src="https://d9b54x484lq62.cloudfront.net/paparazzi/shopping/images/517_tag150x115_1.png" alt="New Kit" align="middle" height="50" width="50"/>',
    //   prices: { wholesale: 2.75, null: 5 },
    //   category: 1,
    //   volume: "2.00",
    //   active: true,
    //   images: [
    //     {
    //       original: "~/p/80560_1_1.jpg",
    //       "25_cropped": "~/tp/80560_1_1.jpg[c25]",
    //       "100_cropped": "~/tp/80560_1_1.jpg[c100]",
    //       "512_cropped": "~/tp/80560_1_1.jpg[c512]",
    //       w125: "~/tp/80560_1_1.jpg.125x200_q85.jpg",
    //       w250: "~/tp/80560_1_1.jpg.250x400_q85.jpg",
    //       w500: "~/tp/80560_1_1.jpg.500x800_q85.jpg",
    //       xxs: "~/tp/80560_1_1.jpg.240x240_q85.jpg",
    //       xs: "~/tp/80560_1_1.jpg[xs]",
    //     },
    //     {
    //       original: "~/p/80560_2_1.jpg",
    //       "25_cropped": "~/tp/80560_2_1.jpg[c25]",
    //       "100_cropped": "~/tp/80560_2_1.jpg[c100]",
    //       "512_cropped": "~/tp/80560_2_1.jpg[c512]",
    //       w125: "~/tp/80560_2_1.jpg.125x200_q85.jpg",
    //       w250: "~/tp/80560_2_1.jpg.250x400_q85.jpg",
    //       w500: "~/tp/80560_2_1.jpg.500x800_q85.jpg",
    //       xxs: "~/tp/80560_2_1.jpg.240x240_q85.jpg",
    //       xs: "~/tp/80560_2_1.jpg[xs]",
    //     },
    //     {
    //       original: "~/p/80560_3_1.jpg",
    //       "25_cropped": "~/tp/80560_3_1.jpg[c25]",
    //       "100_cropped": "~/tp/80560_3_1.jpg[c100]",
    //       "512_cropped": "~/tp/80560_3_1.jpg[c512]",
    //       w125: "~/tp/80560_3_1.jpg.125x200_q85.jpg",
    //       w250: "~/tp/80560_3_1.jpg.250x400_q85.jpg",
    //       w500: "~/tp/80560_3_1.jpg.500x800_q85.jpg",
    //       xxs: "~/tp/80560_3_1.jpg.240x240_q85.jpg",
    //       xs: "~/tp/80560_3_1.jpg[xs]",
    //     },
    //   ],
    //   videos: [],
    //   release_date: "2025-03-18T19:00:00+00:00",
    //   release_dates: { all: "2025-03-18T19:00:00+00:00" },
    //   cl_refs: null,
    //   stock: 0,
    //   style_image: null,
    //   categories: [433, 1, 512, 474],
    //   is_parent: false,
    //   parent: null,
    //   subproducts: [],
    //   date_added: "2025-03-18T12:30:57.557260-04:00",
    //   colors: ["SV"],
    //   inventory_source: "default",
    //   slug: "hoedown-throwdown-silver",
    //   extras: null,
    //   ordering: 100,
    //   deployed: "2025-03-18T19:00:00.000Z",
    // };
    // this.array.value.push(item);
    // this.cache.setItem("hoedown-throwdown-silver", item);
  }

  migrateItem(a: T) {
    // remove the color string from the title,
    // HOPEFULLY this will only be needed while in beta
    const dash = a.name.lastIndexOf(" - ");
    if (dash > -1) {
      const color = a.name.slice(dash + 3); //.trim();
      if (this.colors.includes(color)) {
        a.name = a.name.slice(0, dash).trim();
      }
    }

    // add deployed date for sorting
    // the goal is to make release dates change the sorting after release
    a.deployed = safeDateParse(a.date_added);
    if (a.release_date) {
      const deployed = safeDateParse(a.release_date);
      const offset = new Date(deployed.getTime() - 1000 * 60 * 5);
      if (offset < new Date()) {
        a.deployed = deployed;
      }
    }
    return a;
  }
}
