/*

add to your env.d.ts or other type declarations file

```typescript
declare global {
  interface Window {
    ...
    dataLayer: any[];
    ...
  }
}
```

*/
import { watch } from "vue";
import { createRouter } from "vue-router";

export interface GAOptions {
  prefix?: string;
  debug?: boolean;
}

export function gtag(name: string, ...args: any[]): void {
  window.dataLayer.push(arguments);
}
export function loadGA(
  key: string,
  router: ReturnType<createRouter>,
  opts: GAOptions = {},
): HTMLScriptElement {
  const script = document.createElement("script");
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${key}`;
  document.body.appendChild(script);

  // Global site tag (gtag.js) - Google Analytics
  window.dataLayer = window.dataLayer || [];

  gtag("js", new Date());
  gtag("config", key, { debug_mode: opts.debug ?? false });

  trackPageLoads(router, opts);
  return script;
}

export function trackPageLoads(
  router: ReturnType<createRouter>,
  opts: GAOptions,
): void {
  trackPage((opts.prefix || "") + router.currentRoute.value.path);
  watch([() => router.currentRoute.value.path], (to) => {
    trackPage((opts.prefix || "") + router.currentRoute.value.path);
  });
}

export function trackPage(path) {
  console.info(
    `%c[navigation - ${path}]`,
    "background: #eee; color: darkblue; padding: 2px; border-radius: 4px;",
  );
  gtag("event", "page_view", {
    page_title: document.title,
    page_location: "" + window.location,
    page_path: path,
  });
}
