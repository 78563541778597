import { debounce, Deferred } from "@/utils/bouncer";
import type { ModelBaseDef } from "@/utils/defs";
import { Patcher, ProductPatcher } from "@/utils/patcher";
import { checkForProduct } from "@/utils/shop";
import type { MiniProduct } from "@paparazzi/types/paparazzi.api.serializers";
import { createLogger } from "@paparazzi/utils/debug";
import { useLocalStorageStore } from "@virgodev/bazaar/functions/localstorage/store";
import { defineStore } from "pinia";
import sortBy from "sort-by";
import { computed, ref, toRef, watch, type Ref } from "vue";
import type { Category, Mirror, Product, Promotion } from "./defs/shop_defs";
import { typeOptions } from "./defs/shop_defs";
import { useFirebaseStore } from "./firebase";
import { useSSRStore } from "./ssr";
import { useTimesyncStore } from "./timesync";
import { useUserStore } from "./user";

const VERSION = 2;
const log = createLogger("shop");
const featuredLog = createLogger("shop-featured");

export const useShopStore = defineStore("shop", () => {
  const uncachedDeferred = new Deferred();
  const readyDeferred = new Deferred();
  const ssr = useSSRStore();
  const firebase = useFirebaseStore();
  const user = useUserStore();
  const timesync = useTimesyncStore();
  const storage = useLocalStorageStore();
  const status = ref<string>("");
  const error = ref<string>("");
  const objects: {
    products: Ref<Product[]>;
    categories: Ref<Category[]>;
    promos: Ref<Promotion[]>;
  } = {
    products: ref<Product[]>([]),
    categories: ref<Category[]>([]),
    promos: ref<Promotion[]>([]),
  };
  const now = new Date().toISOString();
  const lastUpdate = ref<Record<string, string>>({
    products: now,
    categories: now,
    promos: now,
  });
  const percents = {
    products: ref(0),
    categories: ref(0),
    promos: ref(0),
  };
  const running = ref<Record<string, boolean>>({});
  const orderMax = ref(user.props.order_max?.max || 50);
  const isApp = ref(false);

  const isReady = computed(() => {
    return percents.products.value >= 1 && percents.categories.value >= 1;
  });
  const allowedCategories = computed(() => {
    const retval = objects.categories.value.filter((cat: Category) => {
      return (
        cat.active &&
        (cat.allow_all ||
          cat.allowed_groups?.length === 0 ||
          cat.allowed_groups.some((g) => user.groups.indexOf(g) > -1))
      );
    });
    retval.sort(sortBy("order"));
    return retval;
  });
  const indexedCategories = computed(() => {
    return allowedCategories.value.filter(
      (c: Category) => c.indexed === undefined || c.indexed === true,
    );
  });
  const staticCategories = computed(() => {
    return objects.categories.value.filter((category: Category) => {
      return category.name !== "Children" && category.indexed === false;
    });
  });
  const preorderCategoriesId = computed(() => {
    return objects.categories.value
      .filter((category: Category) => {
        return /Pre-Order/.test(category.name);
      })
      .map((c) => c.id);
  });
  const staticCategoriesId = computed(() => {
    return staticCategories.value.map((c: Category) => c.id);
  });
  const ancestors = computed<{ [key: string]: Category[] }>(() => {
    const retval: { [key: string]: Category[] } = {};
    for (const category of objects.categories.value) {
      retval[category.id] = getAncestors(category);
    }
    return retval;
  });
  const descendents = computed<{ [key: string]: Category[] }>(() => {
    const retval: { [key: string]: Category[] } = {};
    for (const category of objects.categories.value) {
      retval[category.id] = getDescendents([category]);
    }
    return retval;
  });
  const counts = computed<{ [key: string]: number }>(() => {
    const retval: { [key: string]: number } = {};
    for (const category of objects.categories.value) {
      retval[category.id] = allowedProducts.value.filter((p) =>
        p.categories.some((cid) =>
          descendents.value[category.id].map((c) => c.id).includes(cid),
        ),
      ).length;
    }
    return retval;
  });
  const allowedProducts = computed(() => {
    return objects.products.value.filter((product: Product) => {
      return allowedCategories.value.some((c: Category) =>
        product.categories.includes(c.id),
      );
    });
  });
  const indexedProducts = computed(() => {
    return objects.products.value.filter((product: Product) => {
      return (
        !product.parent &&
        indexedCategories.value.some((c: Category) =>
          product.categories.includes(c.id),
        )
      );
    });
  });
  const metalParent = computed((): Category | undefined => {
    const cat = objects.categories.value.find(
      (c) => !c.parent && c.name === "Metal",
    );
    return cat;
  });
  const colorParent = computed((): Category | undefined => {
    const cat = objects.categories.value.find(
      (c) => !c.parent && c.name === "Color",
    );
    return cat;
  });
  const collections = computed((): Category[] => {
    return objects.categories.value.filter((c) => /Collection$/.test(c.name));
  });
  const metals = computed(() => {
    const parentId = metalParent.value?.id || -1;
    return objects.categories.value.filter((c) => c.parent === parentId);
  });
  const colors = computed(() => {
    const parentId = colorParent.value?.id || -1;
    return objects.categories.value.filter((c) => c.parent === parentId);
  });

  const patchers: { [key: string]: Patcher<ModelBaseDef> } = {
    products: new ProductPatcher(
      "products",
      objects.products,
      percents.products,
      firebase.mirrors.find((m) => m.name === "products"),
      undefined,
    ),
    categories: new Patcher(
      "categories",
      objects.categories,
      percents.categories,
      firebase.mirrors.find((m) => m.name === "categories"),
      ["order", "name"],
    ),
    promos: new Patcher(
      "promos",
      objects.promos,
      percents.promos,
      firebase.mirrors.find((m) => m.name === "promos"),
    ),
  };

  // watch for unreleased, timed products
  // after release, set deployed date to the release date so items rise to the top
  watch(
    () => timesync.now,
    () => {
      const notReleased = objects.products.value.filter(
        (p) =>
          p.active && p.release_date && p.deployed < new Date(p.release_date),
      );
      let changed = 0;
      for (const product of notReleased) {
        const deployed = new Date(product.release_date);
        const offset = new Date(deployed.getTime() - 1000 * 60 * 5);
        if (offset.getTime() <= timesync.now) {
          product.deployed = deployed;
          changed += 1;
        }
      }
      if (changed > 0) {
        objects.products.value = objects.products.value;
        lastUpdate.value.product = new Date().toISOString();
      }
    },
  );

  watch(
    toRef(firebase, "productsData"),
    async () => {
      if (await debounce("products-data-purge")) {
        for (const product of objects.products.value) {
          if (!firebase.productsData[product.slug]) {
            console.warn("purging old:", product.slug);
            const index = objects.products.value.indexOf(product);
            objects.products.value.splice(index, 1);
            patchers.products.cache.removeItem(`${product.id}`);
          }
        }
      }
    },
    { deep: true },
  );
  watch(isReady, async () => {
    if (isReady.value) {
      readyDeferred.resolve();
    }
  });
  watch(
    () => user.props.order_max,
    () => {
      orderMax.value = user.props.order_max?.max || 50;
    },
  );
  watch(percents.products, (v) => {
    if (v >= 1) {
      ssr.provides("products");
    }
  });
  watch(percents.categories, () => {
    ssr.provides("categories");
  });
  watch(percents.promos, () => {
    ssr.provides("promos");
  });

  watch(
    () => firebase.mirrors.find((m) => m.name === "products-index")?.data,
    async (value) => {
      if (value) {
        await patchers.products._cachePromise;

        for (const key in value) {
          const existing = objects.products.value.find(
            (p: ModelBaseDef) => p.slug === key,
          );
          if (value[key] && existing) {
            if (existing.stock !== value[key]) {
              existing.stock = value[key];
            }
          } else {
            patchers.products.patch(key, { timestamp: new Date(timesync.now) });
          }
        }
      }
    },
    { deep: true },
  );
  watch(
    () => firebase.mirrors.find((m) => m.name === "products")?.updated,
    () => {
      const mirror = firebase.mirrors.find((m) => m.name === "products");
      if (mirror) {
        processFirebaseQueue(mirror);
      }
    },
  );
  watch(
    () => firebase.mirrors.find((m) => m.name === "categories")?.updated,
    () => {
      const mirror = firebase.mirrors.find((m) => m.name === "categories");
      if (mirror) {
        processFirebaseQueue(mirror);
      }
    },
  );
  watch(
    () => firebase.mirrors.find((m) => m.name === "promos")?.updated,
    () => {
      const mirror = firebase.mirrors.find((m) => m.name === "promos");
      if (mirror) {
        processFirebaseQueue(mirror);
      }
    },
  );
  async function processFirebaseQueue(mirror: Mirror) {
    if (
      !running.value[mirror.name] &&
      (await debounce(`process-mirror-${mirror.name}`))
    ) {
      running.value[mirror.name] = true;
      // () => {
      //   const value = firebase.mirrors.find((m) => m.name === "promos")?.data;
      //   if (value) {
      //     for (const key in value) {
      //       patchers.promos.patch(key, { timestamp: new Date(value[key]) });
      //     }
      //   }
      // },
      while (mirror.queue.length > 0) {
        const item = mirror.queue.shift();
        if (item) {
          if (item.action === "delete") {
            patchers[mirror.name].remove(item.key);
          } else if (item.value) {
            patchers[mirror.name].patch(item.key, {
              timestamp: new Date(item.value),
            });
          }
        }
      }
      running.value[mirror.name] = false;
    }
  }
  watch(() => firebase.featured, checkFeatured, { deep: true });

  function ready(): Promise<void> {
    return readyDeferred.promise;
  }

  function getPrice(product: Product): number {
    if (user.isRep && product.prices?.wholesale) {
      return product.prices.wholesale;
    }
    return product.prices?.retail || product.prices?.null || 0;
  }

  function getProductNameWithoutColor(product: string): string {
    return product.split(" - ")[0];
  }

  function getProductColors(product: Product) {
    const matches = objects.categories.value.filter((c) =>
      (product.categories || []).includes(c.id),
    );
    const colors = matches.filter((c) => c.parent === colorParent.value?.id);
    const metals = matches.filter((c) => c.parent === metalParent.value?.id);
    return {
      colors,
      metals,
      all: [...colors, ...metals],
    };
  }

  function getProductColorNames(product: MiniProduct | Product): string[] {
    let item = objects.products.value.find((p) => p.id === product.id);
    if (item) {
      return getProductColors(item).all.map((c) =>
        c.name.replace(" Accessories", ""),
      );
    }
    return [];
  }

  function getProductType(product: MiniProduct | Product): string {
    let item = objects.products.value.find((p) => p.id === product.id);
    if (item) {
      const matches = objects.categories.value.filter(
        (c) =>
          (item.categories || []).includes(c.id) &&
          typeOptions.includes(c.name),
      );
      return matches.map((c) => c.name).join(", ") || "Other";
    }
    return "Other";
  }

  function getAncestors(category: Category): Category[] {
    const parent = objects.categories.value.find(
      (c) => c.id === category.parent,
    );
    if (parent) {
      return [...getAncestors(parent), category];
    }
    return [category];
  }

  function getDescendents(categories: Category[]): Category[] {
    const pids = categories.map((c) => c.id);
    const parents = objects.categories.value.filter((c) =>
      pids.includes(c.parent),
    );
    if (parents.length > 0) {
      return [...getDescendents(parents), ...categories];
    }
    return [...categories];
  }

  function explainWhyHidden(item: Product) {
    return {
      active: item.active,
      inStock: item.stock > 0,
      categories: item.categories.map((c) => {
        const cat1 = indexedCategories.value.find((c2) => c2.id === c);
        const cat2 = objects.categories.value.find((c2) => c2.id === c);
        if (cat2 && !cat1) {
          return { id: cat2.id, name: cat2.name, status: "invalid" };
        } else if (cat1) {
          return { id: cat1.id, name: cat1.name, status: "allowed" };
        } else {
          return { id: c, status: "unkown" };
        }
      }),
    };
  }

  async function checkFeatured() {
    if (firebase.featured && (await debounce("check-featured", 1000 * 60))) {
      const dt = new Date(firebase.featured.dt);
      featuredLog("Featured updated", firebase.featured);

      if (dt.getTime() + 1000 * 60 * 60 > timesync.now) {
        featuredLog("waiting for patcher to finish");
        await patchers.products.promise;
        await readyDeferred.promise;
        featuredLog("patcher done, dianosing problems...");
        for (const item of firebase.featured.items) {
          featuredLog("checking", item);
          const exists = indexedProducts.value.find((p) => p.slug === item);
          const hidden = objects.products.value.find((p) => p.slug === item);
          if (!exists) {
            if (!hidden) {
              featuredLog(">> Missing product", item);
              // await checkForProduct(item);
              // } else {
              //   featuredLog(
              //     ">> Item hidden",
              //     item,
              //     copy(hidden),
              //     explainWhyHidden(hidden),
              //   );
            }
          }
        }
      }
      featuredLog("problems diagnosis done");
    }
  }

  function clear() {
    for (const key in patchers) {
      patchers[key].cache.clear();
    }
    objects.categories.value = [];
    objects.products.value = [];
    objects.products.value = [];
  }

  function getPatcher(key: string): Patcher<ModelBaseDef> {
    return patchers[key];
  }

  function getMax(productId: number | undefined) {
    let max = orderMax.value;
    const product = objects.products.value.find((p) => p.id === productId);
    if (product && preorderCategoriesId.value.includes(product.category)) {
      max = 1000000;
    }
    return max;
  }

  async function setup() {
    // log("setting up shop...");
    const promises: Promise<any>[] = [];
    for (const key in patchers) {
      const patcher = patchers[key];
      promises.push(patcher.loadCache());
    }
    await Promise.all(promises);
    uncachedDeferred.resolve();

    // sync missing items with existing mirror
    for (const key in patchers) {
      const patcher = patchers[key];
      const mirror = firebase.mirrors.find((m) => m.name === key);
      if (mirror && mirror.data) {
        patcher.finishPatch();
        for (const sku in mirror.data) {
          const item = patcher.array.value.find(
            (i) => patcher.getKey(i) === sku,
          );
          if (!item) {
            // log("patcher missing", key, sku);
            patcher.patch(sku, mirror.data[sku]);
          }
        }
      }
    }

    // DEBUGGING
    window._shop = {
      products: objects.products,
      checkForProduct: checkForProduct,
      clear,
    };
  }

  return {
    promises: {
      uncached: uncachedDeferred.promise,
    },
    objects,
    percents,
    products: indexedProducts,
    categories: indexedCategories,
    patchers,
    allowedProducts,
    allowedCategories,
    staticCategories,
    staticCategoriesId,
    promos: objects.promos,
    orderMax,
    metalParent,
    colorParent,
    collections,
    metals,
    colors,
    ancestors,
    descendents,
    counts,
    isReady,
    isApp,
    lastUpdate,
    setup,
    ready,
    getPrice,
    getProductNameWithoutColor,
    getProductColors,
    getProductColorNames,
    getProductType,
    getPatcher,
    getMax,
  };
});
